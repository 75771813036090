module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 640px)","md":"(max-width: 768px)","lg":"(max-width: 1024px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DRINKYN | Busca y compara el precio de tus licores favoritos","short_name":"DRINKYN","description":"DRINKYN, Licorerías Delivery las 24 horas, Compra Licores con garantia y seguridad, la mayor variedad de licores del mundo en un solo lugar, delivery de licores las 24 horas. ¡Pide Ya!.","start_url":"/","background_color":"#ffffff","theme_color":"#FF0000","display":"minimal-ui","icon":"./src/images/favicon-drinkyn.svg","icons":[{"src":"./src/images/favicon-drinkyn.svg","sizes":"192x192","type":"image/svg"},{"src":"./src/images/favicon-drinkyn.svg","sizes":"512x512","type":"image/svg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c6dd8a72e1e4281d3c57b4743452f89c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TJHD2MTG","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
