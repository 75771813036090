import React, { useState } from 'react';
import { Drawer } from 'vaul';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { navigate } from 'gatsby';

const menuMobile = [
    {
        name: "Vinos y Espumantes",
        link: "vinos",
        links: [
            {
                name: "Vinos",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/vinos.png",
                link: "vinos"
            },
            {
                name: "Espumantes",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/espumantes.png",
                link: "espumante-champagne-y-burbuja"
            },
        ]
    },
    {
        name: "Licores",
        link: "licores",
        links: [
            {
                name: "Whisky",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/whiskys.png",
                link: "whisky"
            },
            {
                name: "Ron",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/rones.png",
                link: "ron"
            },
            {
                name: "Tequila",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/tequilas.png",
                link: "tequila"
            },
            {
                name: "Vodka",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/vodkas.png",
                link: "vodka"
            },
            {
                name: "Gin",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/gins.png",
                link: "gin"
            },

            {
                name: "Pisco",
                image: "https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/piscos.png",
                link: "pisco"
            }
        ]
    },



]

const DrawerMenu = (props) => {

    const { open, setOpen } = props


    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setIsDragging(false);
        setStartPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
        const distance = Math.sqrt(
            Math.pow(e.clientX - startPosition.x, 2) + Math.pow(e.clientY - startPosition.y, 2)
        );

        if (distance > 5) {
            setIsDragging(true); // Considera como un arrastre si se supera el umbral
        }
    };

    const handleMouseUp = (slug) => {
        if (!isDragging) {
            onClick(slug)
        }
    };

    const onClick = (slug) => {
        navigate(`/${slug}`)
        setOpen(false)
    }

    return (
        <>

            <Drawer.Root open={open} onOpenChange={setOpen} direction="left" >
                <Drawer.Portal>
                    <Drawer.Overlay className="fixed inset-0 z-[100] bg-black/40" />
                    <Drawer.Content aria-describedby={"Busca tus licores y vinos favoritos"} className="bg-white inset-y-0 left-0 z-[100] h-full min-h-full fixed outline-none  w-[410px] max-w-[90%] " style={{ '--initial-transform': 'calc(100% + 8px)' }}>
                        <Drawer.Title className='sr-only'>Menu Principal</Drawer.Title>
                        <Drawer.Description className='sr-only'>"Navega en las principales categorias de DRINKYN"</Drawer.Description>
                        <div className='flex flex-col max-h-full'>
                            <div className='relative min-h-16 border-b flex justify-center items-center px-4'>
                                <svg className="w-auto h-5 fill-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 240.53">
                                    <path d="m0,240.53V.28h34.2c7.87,0,102.31-12.11,102.31,122.04,0,68.5-25.12,118.21-96.26,118.21H0Zm91.41-118.53c0-30.59-9.08-79.02-46.31-79.02v154.85c36.32,1.91,46.31-45.88,46.31-75.83Z" />
                                    <path d="m160.43,240.53V.28h45.4c28.76,0,80.21,3.19,80.21,68.82,0,26.45-9.99,49.07-32.39,62.13l39.65,109.29h-47.52l-39.65-110.88h-.61v110.88h-45.1Zm45.1-137.01l7.87.32c20.58,0,28.76-14.66,28.76-34.41,0-17.21-7.87-31.23-30.57-29.95h-6.05v64.04Z" />
                                    <path d="m365.96.28v240.25h-45.1V.28h45.1Z" />
                                    <path d="m441.32.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
                                    <path d="m616.58.28v107.38h.61L654.72.28h46.01l-42.07,110.88,47.52,129.36h-47.52l-41.47-122.35h-.61v122.35h-45.1V.28h45.1Z" />
                                    <path d="m761.57.28l23.61,65.32,3.33,11.79h.61l5.15-17.84L814.54.28h50.55l-53.27,125.22v115.03h-45.1v-115.03L711.93.28h49.64Z" />
                                    <path d="m925.63.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
                                </svg>
                                <button type='button' onClick={() => { setOpen(false) }} className='absolute w-14 flex items-center justify-center h-full hover:bg-gray-50  left-0 top-0'><span className='sr-only'>Cerrar Menu</span> <ChevronLeftIcon className='h-6 w-6' /></button>
                            </div>
                            <div className="grow  flex flex-col overflow-y-auto ">
                                {menuMobile.map((item, index) => {
                                    return (
                                        <div key={index} className='flex flex-col font-bold '>
                                            <div className='flex text-xl items-center px-4 py-4 hover:bg-gray-50' role="button" tabIndex={0} onMouseDown={handleMouseDown}
                                                onMouseMove={handleMouseMove}
                                                onMouseUp={() => { handleMouseUp(item.link) }} onKeyDown={() => { onClick(item.link) }} onClick={null}>
                                                {item.name}
                                            </div>
                                            {item.links.map((item, index) => {
                                                return <div key={index} className="p-4 pl-2 flex items-center hover:bg-gray-50 font-bold text-lg no-draggable border-b" role="button" tabIndex={0} onMouseDown={handleMouseDown}
                                                    onMouseMove={handleMouseMove}
                                                    onMouseUp={() => { handleMouseUp(item.link) }} onKeyDown={() => { onClick(item.link) }} onClick={null}>
                                                    <img className="w-12 h-12 aspect-square" width='120' height='120' src={item.image} alt={item.name} />
                                                    <span className="ml-2">{item.name}</span>
                                                </div>
                                            })}
                                        </div>)
                                })}

                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root >
        </>
    );
}

export default DrawerMenu 