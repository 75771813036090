
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="w-full bg-white">
        <main className="w-full min-h-screen">{children}</main>
        <section className="mt-20 px-4">
          <h2 className=" max-w-5xl mx-auto text-center font-semibold text-xs md:text-sm uppercase">
            Licorería Lince · Licorería La Victoria · Licorería San Isidrio · Licorería La Molina · Licorería Miraflores · Licorería San Borja · Licorería Barranco · Licorería Lima · Licorería Surco · Licorería San Luis · Licorería San Juan de Lurigancho · Licorería Chorrillos · Licorería Ate · Licorería San Miguel · Licorería San Martin de Porres · Licorería Pueblo Libre · Licorería Breña · Licorería Magdalena · Licorería Surquillo
          </h2>
          <h2 className="max-w-5xl mx-auto text-lg text-center font-bold  uppercase mt-2">
            Las Licorerias unidas en un solo lugar
          </h2>
          <h3 className="max-w-5xl mx-auto font-extrabold text-2xl md:text-4xl text-center mt-12">LOS MEJORES LICORES, MARCAS, LICORERIAS Y PRECIOS EN UN SOLO LUGAR.</h3>
          <div className="max-w-7xl mx-auto w-full gap-6 grid grid-cols-12 mt-12 px-2">
            <div className="col-span-6 md:col-span-3 text-center">
              <h3 className="font-extrabold uppercase mb-2">Licores Premium</h3>
              <p className="text-sm">La mayor selección de licores premium y alta gama, encuentra la más amplia variedad de licores top del mundo en un solo lugar, Rones, Whiskies, Tequilas, Piscos y más.</p>
            </div>
            <div className="col-span-6 md:col-span-3 text-center">
              <h3 className="font-extrabold uppercase mb-2">El mejor precio</h3>
              <p className="text-sm">Descubre el mejor precio de tus licores favoritos. Encuentra tu licor preferido, compara los precios entre las mejores licorerías y selecciona tu mejor opción.</p>
            </div>
            <div className="col-span-6 md:col-span-3 text-center">
              <h3 className="font-extrabold uppercase mb-2">Licorería Delivery</h3>
              <p className="text-sm">Recibe tus licores con seguridad, rapidez y garantía, nos preocupamos por brindarte productos auténticos, con la garantía de las mejores licorerías y en el mejor tiempo.</p>
            </div>
            <div className="col-span-6 md:col-span-3 text-center">
              <h3 className="font-extrabold uppercase mb-2">Atención y asesoria</h3>
              <p className="text-sm">Nuestros asesores expertos pueden ayudarte a encontrar la mejor opción que se ajuste a ti, también te brindaremos consejos, recetas, consejos de autenticidad de productos, tips y mucho más.</p>
            </div>
          </div>
        </section >
        <Footer />
      </div >
    </>
  )
}

export default Layout
