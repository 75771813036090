import React from "react"

const SkeletonProductSearch = () => {


    return (

        <div className='px-2 py-2'>
            <div className='flex'>
                <div
                    className="w-24 h-24 aspect-square bg-slate-200 animate-pulse rounded-md"
                >
                </div>
                <div className='p-2 w-full' >
                    <p className='leading-tight font-semibold h-6 w-full bg-slate-200 animate-pulse rounded-md'>

                    </p>
                    <div className="h-6 w-24 flex justify-left items-center mb-2 gap-1 mt-2 bg-slate-200 animate-pulse rounded-md">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonProductSearch


