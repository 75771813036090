import { Link } from "gatsby"
import * as React from "react"

const Footer = (props) => {

    return (
        <footer className="">
            <div className="flex flex-col max-w-2xl mx-auto my-8 px-4 py-4 text-center text-gray-600">
                <p className="text-base">
                    Almendariz.com.pe · ElPozito.com.pe · LicoresMiMar.pe · Licorerias.com.pe · Metro.pe · Wong.pe · PlazaVea.com.pe
                </p>
                <p className="pt-2">¡Las mejores Licorerías Unidas en un solo lugar!</p>
            </div>
            <div className=" bg-gray-800 text-white font-semibold text-2xl md:text-4xl text-center py-5">
                <p className="max-w-6xl mx-auto p-2 md:p-4 tracking-tighter md:tracking-wide md:leading-[45px] m-0">
                    TOMAR BEBIDAS ALCOHÓLICAS EN EXCESO ES DAÑINO. ESTÁ PROHIBIDA LA VENTA DE ALCOHOL A MENORES DE 18 AÑOS.
                </p>
            </div>
            <div className="px-14 pt-10 pb-8 bg-gray-100 text-gray-700 w-full">
                <div className="grid grid-cols-12 gap-6 md:gap-8 lg:gap-12">
                    <div className="col-span-12 md:col-span-2 flex flex-col item-center justify-center gap-4 ">
                        <div className="flex flex-col items-center justify-center">
                            <div>
                                <svg className="w-8 mx-auto flex justify-center items-center h-auto fill-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.14 1024">
                                    <path d="m357.4,346.77l-30.06-20.68c-30.44-20.94-48.83-55.34-49.35-92.29l-1.19-158.92s2.26-55.29-6.83-62.15C251.76-1.02,199.07.02,199.07.02c0,0-52.69-1.03-70.9,12.72-9.09,6.86-6.83,62.15-6.83,62.15l-1.19,158.92c-.51,36.94-18.91,71.35-49.35,92.29l-30.06,20.68C14.69,366.77-.41,397.89,0,430.74l2.35,185.28,5.52,324.82c0,32.13,14.18,61.75,65.77,73.04,37.2,8.14,125.42,10.13,125.42,10.13,0,0,88.22-1.99,125.42-10.13,51.59-11.29,65.77-40.91,65.77-73.04l5.52-324.82,2.35-185.28c.42-32.85-14.68-63.96-40.73-83.97Z" />
                                </svg>
                            </div>
                            <div className="mt-3">
                                <svg className="w-auto h-5 fill-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 240.53">
                                    <path d="m0,240.53V.28h34.2c7.87,0,102.31-12.11,102.31,122.04,0,68.5-25.12,118.21-96.26,118.21H0Zm91.41-118.53c0-30.59-9.08-79.02-46.31-79.02v154.85c36.32,1.91,46.31-45.88,46.31-75.83Z" />
                                    <path d="m160.43,240.53V.28h45.4c28.76,0,80.21,3.19,80.21,68.82,0,26.45-9.99,49.07-32.39,62.13l39.65,109.29h-47.52l-39.65-110.88h-.61v110.88h-45.1Zm45.1-137.01l7.87.32c20.58,0,28.76-14.66,28.76-34.41,0-17.21-7.87-31.23-30.57-29.95h-6.05v64.04Z" />
                                    <path d="m365.96.28v240.25h-45.1V.28h45.1Z" />
                                    <path d="m441.32.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
                                    <path d="m616.58.28v107.38h.61L654.72.28h46.01l-42.07,110.88,47.52,129.36h-47.52l-41.47-122.35h-.61v122.35h-45.1V.28h45.1Z" />
                                    <path d="m761.57.28l23.61,65.32,3.33,11.79h.61l5.15-17.84L814.54.28h50.55l-53.27,125.22v115.03h-45.1v-115.03L711.93.28h49.64Z" />
                                    <path d="m925.63.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
                                </svg>
                            </div>
                        </div>
                        <div className="text-xs">
                            <p className="font-thin m-0">
                                Razón Social: <strong className="font-bold">Licorerias Peru S.A.C.</strong>
                            </p>
                            <p className="font-thin m-0">
                                R.U.C.: <strong className="font-bold">20611486643</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <p className="font-extrabold text-lg mb-3">
                            Nuestra Compañia
                        </p>
                        <ul className="space-y-2 text-sm">
                            <li className="">
                                Cómo funciona DRINKYN
                            </li>
                            <li className="">
                                Sobre nosotros
                            </li>
                            <li>
                                <Link to="/licoreria-delivery">
                                    Nuestro equipo
                                </Link>
                            </li>
                            <li>
                                <Link to="/licoreria-delivery">
                                    Partners
                                </Link>
                            </li>
                            <li>
                                <Link to="/licoreria-delivery">
                                    Preguntas más frecuentes
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <p className="font-extrabold text-lg">
                            Asociate con nosotros
                        </p>
                        <ul className="space-y-2 mt-3 text-sm">
                            <li>
                                Para Empresas
                            </li>
                            <li >
                                Minoristas
                            </li>
                            <li>
                                Marcas
                            </li>
                            <li>
                                Afiliate con nosotros
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <p className="font-extrabold text-lg">
                            Ponte en contacto
                        </p>
                        <ul className="space-y-2 mt-3 text-sm">
                            <li>
                                <Link to='/politicas-de-cambios-y-devoluciones'>
                                    Soporte
                                </Link>
                            </li>
                            <li>
                                <Link to='/politicas-de-cambios-y-devoluciones'>
                                    Recursos de apoyo al alcoholismo
                                </Link>
                            </li>
                            <li>
                                <Link to='/politicas-de-cambios-y-devoluciones'>
                                    Politicas de cambio y devolución
                                </Link>
                            </li>
                            <li>
                                <Link to='/terminos-y-condiciones'>
                                    Terminos y Condiciones
                                </Link>
                            </li>
                            <li>
                                <Link to='/terminos-y-condiciones'>
                                    Ciudades DRINKYN
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <p className="font-extrabold text-lg">
                            Atención al Cliente
                        </p>
                        <ul className="space-y-2 mt-3 text-sm">
                            <li>
                                <a href="tel:+51999999999" className="font-bold text-lg">
                                    <span className="text-lg">+51</span> 999 999 999
                                </a>
                            </li>
                            <li>
                                <p className="text-sm">
                                    Lun. - Dom. de 8:00 am a 11:00 pm
                                </p>
                            </li>
                            <li>
                                <a className="text-sm" href="mailto:ventas@delicores.com">atencion@drinkyn.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <ul className="">
                            <li className="bg-white p-4 rounded-lg max-w-[150px]">
                                <Link to='/libro-de-reclamaciones'>
                                    <img className="w-full lazyload" width={279} height={165} loading="lazy" alt="Libro de reclamaciones" src='https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/279x165/libro-de-reclamaciones-35c47757c5.png' />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-gray-100 border-t text-gray-600 text-base font-medium flex justify-center text-center leading-none items-center h-12 py-4">
                    DRINKYN  Ⓡ {new Date().getFullYear()} &middot; Todos los derechos reservados
                </div>
            </div>


        </footer >
    )
}


export default Footer
