import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { Bars3Icon, MagnifyingGlassIcon, } from '@heroicons/react/24/outline'
import DrawerSearch from "../DrawerSearch"
import DrawerMenu from "../DrawerMenu"

const navigation = [
  /*
  {
    name: 'Cervezas',
    href: null,
    sections: [
      {
        name: "Estilos de Cerveza",
        href: null,
        sections: [
          {
            sections: [
              { name: "IPA", href: "IPA" },
              { name: "Hard Seltzer", href: "Hard Seltzer" },
              { name: "Lager", href: "Lager" },
              { name: "Light Beer", href: "Light Beer" },
              { name: "Ale", href: "Ale" },
              { name: "Stout", href: "Stout" },

            ]
          },
          {
            sections: [{ name: "Sour Beer", href: "Sour Beer" },
            { name: "Belgian-Style Ale", href: "Belgian-Style Ale" },
            { name: "Cider", href: "Cider" },
            { name: "New England / Hazy IPA", href: "New England / Hazy IPA" },
            { name: "Non-Alcoholic", href: "Non-Alcoholic" },
            { name: "Shop All Beer", href: "Shop All Beer" },]
          }
        ],

      },
      {
        name: "Trending",
        href: null,
        sections: [
          {
            sections: [
              { name: "Craft Beer", href: "Craft Beer" },
              { name: "Variety Packs", href: "Variety Packs" },
              { name: "Large Format", href: "Large Format" },
              { name: "Gluten Free / Reduced", href: "Gluten Free / Reduced" },
              { name: "Shop By Brand", href: "Shop By Brand" },
            ]
          }
        ]
      },
      {
        name: "More",
        href: null,
        sections: [
          {
            sections: [
              { name: "Blog", href: "Blog" },
              { name: "Pairing Guides", href: "Pairing Guides" },
              { name: "Buying Guides", href: "Buying Guides" },
            ]
          }
        ]
      },
      {
        name: "Gifting",
        href: null,
        sections: [
          {
            sections: [
              { name: "Beer Gifts", href: "Beer Gifts" },
            ]
          }
        ]
      }

    ]
  },
  */
  {
    name: 'Vinos',
    href: 'vinos',
    sections: [
      {
        name: "Variedad de vinos",
        href: 'vinos',
        sections: [
          {
            sections: [
              {
                name: "Vino Tinto",
                href: 'vino-tinto',
                /*
                sections: [
                  { name: "Malbec", href: "malbec" },
                  { name: "Cabernet Sauvignon", href: "cabernet-sauvignon" },
                  { name: "Merlot", href: "merlot" },
                ]
                  */
              }
            ]
          },
          {
            sections: [
              {
                name: "Vino Blanco",
                href: "vino-blanco",
                /*
                sections: [
                  { name: "Chardonnay", href: "chardonnay" },
                  { name: "Sauvignon Blanc", href: "sauvignon-blanc" },
                  { name: "Torrontés", href: "torrontes" },
                ]
                */
              }

            ]
          },
          {
            sections: [
              {
                name: "Espumantes",
                href: "espumante-champagne-y-burbuja",
                /*
                sections: [
                  { name: "Champagne", href: "champagne" },
                  { name: "Cava", href: "cava" },
                ]
                  */
              }

            ]
          }
        ],

      },
    ]
  },
  {
    name: 'Licores',
    href: 'licores',
    sections: [
      {
        name: "Tipo de Licor",
        href: 'licores',
        sections: [
          {
            sections: [
              { name: "Whisky", href: "whisky" },
            ]
          },
          {
            sections: [
              { name: "Tequila", href: "tequila" },
            ]
          },
          {
            sections: [
              { name: "Ron", href: "ron" },
            ]
          },
          {
            sections: [
              { name: "Vodka", href: "vodka" },
            ]
          },
          {
            sections: [
              { name: "Gin", href: "gin" },
            ]
          },
          {
            sections: [
              { name: "Pisco", href: "pisco" },
            ]
          },
          {
            sections: [
              { name: "Otros Licores", href: "licores-cordiales-y-aguardientes" },
            ]
          }
        ],
      },
    ]
  },
  /*
  { name: 'Complementos', href: '/complementos' },
  { name: 'Negocios', href: '/negocios' },
  { name: 'Regalos', href: '/regalos' },
  */
]


const Header = ({ siteTitle }) => {

  const [openMenu, setOpenMenu] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  return (
    <>
      <header className="mx-auto bg-white flex h-16 md:h-[68px]  items-center  border-b z-30 sticky top-0">
        <div className="grow h-full flex flex-1  items-center justify-start">
          <button onClick={() => { setOpenMenu(true) }} className="flex md:hidden items-center justify-center w-14 md:w-16  hover:bg-gray-50 rounded h-full">
            <Bars3Icon className="h-6 w-6 text-black" />
            <span className="sr-only">Carrito</span>
          </button>
          <Link to="/" className=" h-full flex justify-center items-center  mx-auto md:mx-8">
            <span className="sr-only">Inicio Drinkyn</span>
            <svg className="hidden md:block w-auto h-8 fill-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 425.73">
              <path d="m0,179.37l54.61-9.63c1.04.04,2.02-.03,2.92-.19.9-.16,1.88-.22,2.92-.19,15.54-1.21,31.04,4.35,46.49,16.69,15.44,12.34,25.96,33.73,31.54,64.18.49,2.75.95,5.67,1.39,8.76.44,3.09.74,6.25.9,9.5,3.21,39.38-2.19,72.59-16.19,99.61-14.01,27.03-35.89,43.17-65.65,48.41l-52.19,9.2L0,179.37Zm51.68,195.21c.7-.12,1.46-.26,2.26-.4,13.51-2.16,23.84-12.11,30.97-29.86,7.14-17.74,10.63-36.42,10.49-56.04.09-4.16-.04-8.18-.41-12.04s-.89-7.7-1.56-11.51c-2.58-14.61-7.61-26.65-15.08-36.14-7.48-9.49-17.76-13.08-30.83-10.77-.2.04-.41.07-.6.11-.2.04-.51.09-.91.16l2.99,156.32c1.08.25,1.97.31,2.68.18Z" />
              <path d="m156.87,151.71l47.67-8.41c26.99-4.54,46.75-2.13,59.29,7.23,12.54,9.36,20.08,21.24,22.61,35.63.3,1.7.54,3.34.72,4.95.18,1.61.31,3.27.42,5,.58,13.87-1.89,27.51-7.4,40.92-5.52,13.41-12.33,23.02-20.43,28.81-1.09.85-2.17,1.53-3.22,2.04-1.05.51-2.06.97-3.03,1.35l43.15,105.36-47.36,8.35-42.82-103.45,2.39,110.58-45.25,7.98-6.73-246.35Zm50.72,99.76c.3-.05.65-.11,1.06-.18.44.14.81.18,1.11.13.3-.06.65-.12,1.06-.19,10.26-1.81,18.13-6.85,23.62-15.13,5.49-8.28,8.29-16.8,8.4-25.55-.03-1.31-.09-2.55-.19-3.73-.11-1.18-.27-2.41-.49-3.68-1.19-6.77-4.29-12.23-9.26-16.37-4.98-4.14-12-5.42-21.05-3.82-1.21.21-2.4.48-3.59.8-1.19.32-2.47.71-3.84,1.17l2.07,66.43c.44.14.81.19,1.11.13Z" />
              <path d="m316.16,123.62l45.25-7.98,9.15,245.93-45.25,7.98-9.15-245.93Z" />
              <path d="m392.19,110.22l45.25-7.98,62.08,143.93-4.87-27.63-5.02-125.5,45.25-7.98,6.73,246.35-42.84,7.55-59.61-144.04,3.92,22.23,3.5,131.01-47.67,8.41-6.73-246.36Z" />
              <path d="m563.24,80.06l47.67-8.4,4.35,107.61,33.66-114.31,45.25-7.98-39.61,122.89,53.28,122.23-49.78,8.78-42.14-113.72,4.13,120.43-50.08,8.83-6.73-246.35Z" />
              <path d="m763.15,171.2l-57.21-116.3,52.19-9.2,28.14,74.93,21.94-83.76,52.19-9.2-51.55,138.1,1.26,118.31-45.25,7.98-1.71-120.85Z" />
              <path d="m874.57,25.16l45.25-7.98,62.08,143.93-4.87-27.63-5.02-125.5,45.25-7.98,6.73,246.35-42.84,7.55-59.61-144.04,3.92,22.23,3.5,131.01-47.67,8.41-6.73-246.35Z" />
            </svg>
            <svg className="block md:hidden w-auto h-5 fill-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 240.53">
              <path d="m0,240.53V.28h34.2c7.87,0,102.31-12.11,102.31,122.04,0,68.5-25.12,118.21-96.26,118.21H0Zm91.41-118.53c0-30.59-9.08-79.02-46.31-79.02v154.85c36.32,1.91,46.31-45.88,46.31-75.83Z" />
              <path d="m160.43,240.53V.28h45.4c28.76,0,80.21,3.19,80.21,68.82,0,26.45-9.99,49.07-32.39,62.13l39.65,109.29h-47.52l-39.65-110.88h-.61v110.88h-45.1Zm45.1-137.01l7.87.32c20.58,0,28.76-14.66,28.76-34.41,0-17.21-7.87-31.23-30.57-29.95h-6.05v64.04Z" />
              <path d="m365.96.28v240.25h-45.1V.28h45.1Z" />
              <path d="m441.32.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
              <path d="m616.58.28v107.38h.61L654.72.28h46.01l-42.07,110.88,47.52,129.36h-47.52l-41.47-122.35h-.61v122.35h-45.1V.28h45.1Z" />
              <path d="m761.57.28l23.61,65.32,3.33,11.79h.61l5.15-17.84L814.54.28h50.55l-53.27,125.22v115.03h-45.1v-115.03L711.93.28h49.64Z" />
              <path d="m925.63.28l56.3,144.98.61-.64c-2.72-11.47-3.63-23.9-3.63-36.32V.28h45.1v240.25h-42.68l-55.7-144.98-.61.64,2.12,24.22.3,9.88v110.25h-45.1V.28h43.28Z" />
            </svg>
          </Link>
          <nav className="hidden md:flex h-full">
            <ul className="flex">
              {navigation.map((item, index) => {
                return (
                  <li key={`${index}-${item.name || item.name}`} className="h-full flex justify-center items-center group ">
                    <Link
                      key={item.name}
                      to={`/${item.href}`}
                      className="relative flex h-full items-center justify-center  text-black px-4 font-extrabold text-lg "
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                      <span className="absolute -bottom-[0px] mx-auto group-hover:w-full w-0 h-[2.8px] left-0 right-0 ease-in-out rounded duration-200 bg-[#FF0000]"></span>
                    </Link>
                    {
                      item.sections &&
                      <div className="group-hover:absolute group-hover:flex items-start  hidden min-h-[120px] pt-[28px]  pl-[40px] left-0 right-0 top-[68px] bg-gray-50 bg-opacity-70 backdrop-blur-lg pb-4 shadow-md">
                        {item.sections?.map((item, index) => {
                          return (
                            <section key={`${index}-${item.name || item.name}`}>
                              <Link to={`/${item.href}`} className="h-8 font-bold text-xl leading-none mx-0 my-2 hover:text-[#FF0000] hover:underline" style={{ lineHeight: "0px" }}>{item.name}</Link>
                              <nav className="flex ">
                                {item.sections?.map((item, index) => {
                                  return (
                                    <div key={`${index}-${item.name || item.name}`} className="flex flex-col mt-3">
                                      {
                                        item.sections?.map((item, index) => {
                                          return (
                                            <div className="flex flex-col" key={`${index}-${item.name || item.name}`}>
                                              <Link to={`/${item.href}`} className="h-10 font-semibold hover:text-[#FF0000] hover:underline text-base py-1 leading-8 mr-[2vw]">{item.name}</Link>
                                              {item.sections?.map((item, index) => {
                                                return (
                                                  <Link to={`/${item.href}`} key={`${index}-${item.name || item.name}`} className="h-8 hover:text-[#FF0000] hover:underline text-sm font-normal leading-8 mr-[2vw] pl-3 border-l-[2px] border-l-[#FF0000]">{item.name}</Link>
                                                )
                                              })}
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                })}
                              </nav>
                            </section>
                          )
                        })}
                      </div>
                    }
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
        <div className=" h-full flex  items-center justify-end ">
          <button onClick={() => setOpenSearch(true)} className="flex items-center justify-center w-14 md:w-16  hover:bg-gray-50 rounded h-full">
            <MagnifyingGlassIcon className="w-6 h-6 text-black" />
            <span className="sr-only">Buscador</span>
          </button>
          {/*
          <button className="flex items-center justify-center w-14 md:w-16  hover:bg-gray-50 rounded h-full">
            <UserIcon className="w-6 h-6 text-black" />
            <span className="sr-only">Usuario</span>
          </button>
          <button className="flex items-center justify-center w-14 md:w-16  hover:bg-gray-50 rounded h-full">
            <ShoppingCartIcon className="w-6 h-6 text-gray-800" />
            <span className="sr-only">Carrito</span>
          </button>
            */}
        </div>
      </header >
      <DrawerSearch open={openSearch} setOpen={setOpenSearch} />
      <DrawerMenu open={openMenu} setOpen={setOpenMenu} />
    </>
  )
}

export default Header
